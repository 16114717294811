var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pubUpload" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            disabled: _vm.loadingOpt.loading,
            action: _vm.uploadFileUrl,
            accept: _vm.accept,
            limit: _vm.limit,
            "list-type": _vm.listType,
            "on-exceed": _vm.handleExceed,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.handleUploadSuccess,
            "on-error": _vm.handleUploadError,
            "before-upload": _vm.beforeUpload,
            "file-list": _vm.imgList,
            "show-file-list": _vm.listShow
          }
        },
        [
          _vm.btnText
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingOpt.loading,
                      expression: "loadingOpt.loading"
                    }
                  ],
                  attrs: { size: "small", type: "primary" }
                },
                [_vm._v(_vm._s(_vm.btnText))]
              )
            : _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingOpt.loading,
                      expression: "loadingOpt.loading"
                    }
                  ],
                  attrs: { size: "small", type: "primary" }
                },
                [_vm._v("点击上传")]
              ),
          _vm.tip
            ? _c("p", { attrs: { slot: "tip" }, slot: "tip" }, [
                _c("span", { staticClass: "red" }, [_vm._v("*")]),
                _vm._v(" " + _vm._s(_vm.tip) + " ")
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }