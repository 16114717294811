
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'
import IconRefresh from '@/assets/images/icon_refresh.png'
import IconSuccess from '@/assets/images/success.png'
import { getQRCodeInfo, jsAppQR, checkQRCodeStatus, checkJSAppQR, valid, checkIsOk } from '@/api/common.api'
import { QRCodeCheckParam, QRCodeInfoParam, QRCode } from '@/utils/types'
import { Verify } from 'crypto'
import jsonbigStringParse from '@/utils/jsonbigStringParse'
import * as secret from '@/utils/secret'

enum QRCodeCheckResult {
  Waiting = '未过期',
  Timeout = '已过期',
  Success = '成功'
}
type VerifyResult = {
  icon: string
  text: string
}
const INTERVAL: number = 5000

@Component({
  components: {
    QrcodeVue
  }
})
export default class QrCode extends Vue {
  @Prop({ default: 160 }) private size!: number
  @Prop({ required: false }) private visible?: boolean
  @Prop() private defaultText?: string
  private radio: number = 1
  private icons = {
    refresh: IconRefresh,
    success: IconSuccess
  }
  private loading: boolean = false
  private status: QRCode.VerifyStatus = QRCode.VerifyStatus.Waiting
  private qrcodeUrl: string = ''
  private callbackId: string = ''
  private timer: number = -1
  private btnDisabled: boolean = false
  mounted() {
    this.getQrcodeUrl()
  }
  beforeDestroy() {
    window.clearInterval(this.timer)
  }
  private get verifyResult(): VerifyResult {
    switch (this.status) {
      case QRCode.VerifyStatus.Timeout:
        return { icon: this.icons.refresh, text: '请点击二维码刷新' }
      case QRCode.VerifyStatus.Success:
        return { icon: this.icons.success, text: '认证成功!' }
      default:
        return { icon: '', text: this.defaultText || '请用手机扫码确认' }
    }
  }
  private refreshQRCode(): void {
    if (this.status === QRCode.VerifyStatus.Timeout) {
      this.getQrcodeUrl()
    }
  }
  private getQrcodeUrl(): void {
    this.loading = true
    window.clearInterval(this.timer)
    if (this.radio == 1) {
      valid({})
        .then((res) => {
          if (this.radio == 2) {
            return
          }
          if (res.data.data.verifyFaceUrl) {
            this.qrcodeUrl = res.data.data.verifyFaceUrl
            this.callbackId = res.data.data.callbackId // 回调id
            this.status = QRCode.VerifyStatus.Waiting
            this.timer = window.setInterval(() => {
              this.checkQrcode()
            }, INTERVAL)
          } else {
            this.status = QRCode.VerifyStatus.Timeout
            clearInterval(this.timer)
          }
        })
        .catch((error) => {
          if (this.radio == 2) {
            return
          }
          if (error.data.code === 1050) {
            this.$router.push({ name: 'VerifyDetail', params: { isPass: 'null' } })
            return
          }
          this.status = QRCode.VerifyStatus.Timeout
          clearInterval(this.timer)
        })
        .finally(() => {
          if (this.radio == 2) {
            return
          }
          this.loading = false
        })
    } else {
      jsAppQR({})
        .then((res) => {
          if (this.radio == 1) {
            return
          }
          if (res.data.data) {
            let data = jsonbigStringParse(secret.default.decrypt(res.data.data.data, 'GoxZ3fT0Xnmc0yAu', 'uvz37k2WLPZgUES4'))
            this.qrcodeUrl = JSON.stringify(res.data.data)
            this.callbackId = data.callbackId
            this.status = QRCode.VerifyStatus.Waiting
            this.timer = window.setInterval(() => {
              this.checkQrcode()
            }, INTERVAL)
          }
        })
        .catch((error) => {
          if (this.radio == 1) {
            return
          }
          this.status = QRCode.VerifyStatus.Timeout
          clearInterval(this.timer)
        })
        .finally(() => {
          if (this.radio == 1) {
            return
          }
          this.loading = false
        })
    }
  }
  private checkQrcode(): void {
    const param: any = {
      callbackId: this.callbackId
    }
    if (this.radio == 1) {
      checkIsOk(param)
        .then((res: any) => {
          if (this.radio == 2) {
            return
          }
          if (res.data.data) {
            switch (res.data.data.status) {
              case 0:
                this.status = QRCode.VerifyStatus.Waiting
                break
              case 1:
                this.status = QRCode.VerifyStatus.Success
                clearInterval(this.timer)
                this.$emit('notify', true, this.callbackId)
                break
              case 2:
                this.status = QRCode.VerifyStatus.Timeout
                clearInterval(this.timer)
                // this.$message.error('认证失败，请刷新二维码重试！')
                this.$message.error(res.data.data.result ? res.data.data.result : '认证失败，请刷新二维码重试！')
                break
            }
          }
        })
        .catch((error) => {
          if (this.radio == 2) {
            return
          }
          this.status = QRCode.VerifyStatus.Timeout
          clearInterval(this.timer)
        })
    } else {
      checkJSAppQR(param)
        .then((res) => {
          if (this.radio == 1) {
            return
          }
          if (res.data.data) {
            switch (res.data.data.status) {
              case 0:
                this.status = QRCode.VerifyStatus.Waiting
                break
              case 1:
                this.status = QRCode.VerifyStatus.Success
                clearInterval(this.timer)
                this.$emit('notify', true, this.callbackId)
                break
              case 2:
                this.status = QRCode.VerifyStatus.Timeout
                clearInterval(this.timer)
                this.$message.error(res.data.data.result ? res.data.data.result : '认证失败，请刷新二维码重试！')
                break
            }
          }
        })
        .catch(() => {
          if (this.radio == 1) {
            return
          }
          this.status = QRCode.VerifyStatus.Timeout
          clearInterval(this.timer)
        })
    }
  }
  @Watch('visible')
  private visibleHandler(newVal: boolean, oldVal: boolean) {
    if (newVal === true) {
      this.getQrcodeUrl()
    } else {
      if (this.timer) clearInterval(this.timer)
    }
  }
}
