import request, { request as http } from '@/utils/request'
import {
  AxiosResponseForZCT,
  QRCodeCheckParam,
  QRCodeInfoParam,
  SendCodeParam,
  UploadSignatureParams
} from '@/utils/types'
export const uploadSignatureUrl = `${process.env.VUE_APP_BASE_URL}signature/uploadSignature`
export const uploadFileUrl = `${process.env.VUE_APP_BASE_URL}file/upload`
// export const uploadFarmers = `${process.env.VUE_APP_BASE_URL}farmersInfo/importFarmersInfo`

// 发送短信或邮箱验证码
export function sendCode(data: SendCodeParam) {
  return request({
    url: '/verify/sendCode',
    method: 'POST',
    data
  })
}

// 验证短信/邮箱验证码
export function verifyCode(data: object) {
  return request({
    url: '/verify/verifyCode',
    method: 'POST',
    data
  })
}

// 获取二维码信息
export function getQRCodeInfo(data: QRCodeInfoParam) {
  return request({
    url: '/verify/QRCodeInfo',
    method: 'POST',
    data
  })
}

// 检查二维码状态 是否过期
export function checkQRCodeStatus(data: QRCodeCheckParam) {
  return request({
    url: '/verify/checkQRCode',
    method: 'POST',
    data
  })
}

// 上传印章
export function uploadSignature(data: UploadSignatureParams) {
  return http<AxiosResponseForZCT>({
    url: '/signature/uploadSignature',
    method: 'POST',
    data
  })
}

// 查询印章
export function querySignature(data: { userId: string | number }) {
  return http<AxiosResponseForZCT>({
    url: '/signature/query',
    method: 'POST',
    header: {},
    data
  })
}
//个人人脸识别
export function firstFace(data: Object) {
  return request({
    url: '/faceVerify/personnel/first',
    method: 'POST',
    data: data
  })
}
//个人人脸识别
export function valid(data: Object) {
  return request({
    url: '/faceVerify/personnel/valid',
    method: 'POST',
    data: data
  })
}
//个人人脸识别
export function checkIsOk(data: Object) {
  return request({
    url: '/faceVerify/checkIsOk',
    method: 'POST',
    data: data
  })
}
//上传文件
export function uploadFil(data: Object) {
  return request({
    url: '/file/upload',
    method: 'POST',
    data: data
  })
}

// 捷算app人脸二维码
export function jsAppQR(data: any) {
  return request({
    url: '/common/verify/QRCodeInfo',
    method: 'POST',
    data
  })
}

// 捷算app人脸轮询
export function checkJSAppQR(data: any) {
  return request({
    url: '/common/verify/checkIsOk',
    method: 'POST',
    data
  })
}

// 是否需要人脸
export function isNeedFace(data: any) {
  return request({
    url: '/common/verify/isVerify',
    method: 'POST',
    data
  })
}
