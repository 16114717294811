import request, { request as http } from '@/utils/request'


// 项目列表
export function selectPageOCR(data: Object) {
  return request({
    url: '/common/ocr/selectPageOCR',
    method: 'POST',
    data: data
  })
}

// 企业详情
export function selectBusinessDetailsById(data: Object) {
  return request({
    url: '/common/ocr/selectBusinessDetailsById',
    method: 'POST',
    data: data
  })
}

// 查看结果
export function selectByParam(data: Object) {
  return request({
    url: '/common/ocrExcel/selectByParam',
    method: 'POST',
    data: data
  })
}

// 下载模板
export function downloadTemplate(data: Object) {
  return request({
    url: '/roleFileTypeConfig/getProcessFiles',
    method: 'POST',
    data: data
  })
}

// paf转图片
export function pdfToImg(data: Object) {
  return request({
    url: '/common/ocr/pdfToImg',
    method: 'POST',
    data: data
  })
}

// 文字识别
export function scanWord(data: Object) {
  return request({
    url: '/common/ocr/scanWord',
    method: 'POST',
    data: data
  })
}


// 导入数据url
export const uploadFileUrl = `${process.env.VUE_APP_BASE_URL}common/ocrExcel/importData`
